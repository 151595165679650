// src/components/PrivateRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

// Updated for React Router v7 - now returns a components that either renders children or redirects
function PrivateRoute({ children }) {
    const { user } = useAuth();
    const location = useLocation();

    // Added the state parameter to preserve the attempted URL for redirecting back after login
    return user ? children : <Navigate to="/login" state={{ from: location.pathname }} replace />;
}

export default PrivateRoute;