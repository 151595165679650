// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { PlusCircle } from 'lucide-react';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login, googleSignIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    // Get the redirect path from state or default to home page
    const from = location.state?.from || '/';

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(email, password);
            // Navigate to the page they were trying to access
            navigate(from);
        } catch (error) {
            setError('Failed to log in');
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
            // Navigate to the page they were trying to access
            navigate(from);
        } catch (error) {
            setError('Failed to sign in with Google');
        }
    };

    return (
        <div className="bg-background min-h-screen flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-8 shadow-md w-full max-w-md">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Login</h2>
                {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">{error}</div>}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                        />
                    </div>
                    <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                        Login
                    </button>
                </form>
                <button onClick={handleGoogleSignIn} className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    Sign in with Google
                </button>
                <div className="mt-4 text-center">
                    <Link to="/signup" className="font-medium text-primary hover:text-primary-dark">
                        Don't have an account? Sign up
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Login;